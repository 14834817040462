const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
	mode: "history", // history 模式去除地址栏 # 号
	routes: [
		// ========  test  ========
		// 一级路由，渲染到 App.vue 中的 <router-view />
		{
			// http://127.0.0.1:8081/test/helloWorld
			path: "/test/helloworld",
			name: "测试模块",
			// ES6 箭头函数写法
			component: () => import("@/components/test/HelloWorld.vue"),
			meta: { requireAuth: true },
		},
		// ========  account  ========
		{
			// http://127.0.0.1:8081/login
			path: "/login",
			name: "login",
			// ES6 箭头函数写法
			component: () => import("@/components/account/Login.vue"),
			meta: { requireAuth: false },
		},
		// {
		// 	// http://127.0.0.1:8081/
		// 	path: "/",
		// 	name: "逸趣书城主页",
		// 	redirect: "/",
		// },
		// ========  index  ========
		{
			// http://127.0.0.1:8081/
			path: "/",
			name: "A-JUN",
			// ES6 箭头函数写法
			component: () => import("@/components/index/Index.vue"),
			meta: { requireAuth: true },
		},
		// ========  book-house  ========
		{
			// http://127.0.0.1:8081/book-house/index
			path: "/book-house/index",
			name: "逸趣书城",
			// ES6 箭头函数写法
			component: () => import("@/components/book_house/Index.vue"),
			meta: { requireAuth: true },
		},
		{
			// http://127.0.0.1:8081/book-house/book/:id
			path: "/book-house/book/:id",
			name: "书籍页面",
			// ES6 箭头函数写法
			component: () => import("@/components/book_house/Book.vue"),
			meta: { requireAuth: true },
		},
		{
			// http://127.0.0.1:8081/book-house/category/:id
			path: "/book-house/category/:id",
			name: "书籍种类页面",
			// ES6 箭头函数写法
			component: () => import("@/components/book_house/Category.vue"),
			meta: { requireAuth: true },
		},
		{
			// http://127.0.0.1:8081/book-house/chapter/:id
			path: "/book-house/chapter/:id",
			name: "书籍详情页面",
			// ES6 箭头函数写法
			component: () => import("@/components/book_house/Chapter.vue"),
			meta: { requireAuth: true },
		},
		{
			// http://127.0.0.1:8081/book-house/search
			path: "/book-house/search/:keyword",
			name: "搜索结果列表",
			// ES6 箭头函数写法
			component: () => import("@/components/book_house/Search.vue"),
			meta: { requireAuth: true },
		},
		{
			// http://127.0.0.1:8081/login
			path: "/book-house/bookshelf",
			name: "我的书架",
			component: () => import("@/components/book_house/Bookshelf.vue"),
			meta: { requireAuth: true },
		},
		{
			// http://127.0.0.1:8081/book-house/author/:id
			path: "/book-house/author/:id",
			name: "作者页面",
			// ES6 箭头函数写法
			component: () => import("@/components/book_house/Author.vue"),
			meta: { requireAuth: true },
		},
	],
});
