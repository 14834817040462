// LocalStorage keys
export const TOKEN_OBJECT_KEY = "TokenObject";
export const TOKEN_KEY = "Token";
// 静态资源前缀
// export const STATIC_DOMAIN = "http://127.0.0.1:8888";
export const STATIC_DOMAIN = "http://8.137.16.35:8888";
// 站点相关信息
export const webSiteInfo = {
	copyright: "Copyright © 2022 JunJunLi. All rights reserved.",
	service: "IT云教学服务",
	serviceUrl: "/education/index",
	icp: "蜀ICP备 2024052492号-1",
	icpUrl: "https://beian.miit.gov.cn/",
	prn: "蜀公网安备 **********号",
	prnUrl: "http://www.beian.gov.cn/portal/index",
};
// 用户信息
export const user = {
	id: 1,
	userName: "junjunli",
	birthday: "2001-01-01",
	gender: "female",
	email: "123456@qq.com",
	userImage: "/image/default_big.jpg",
	role: "staff",
	address: "四川",
	profession: "软件工程师",
	webSite: "www.onlyforli.top",
	aboutMe: "也许我这一生都在和自己的普通做抗争，这种抗争也许是徒劳的，但这又怎么样呢？",
	skills: [
		{ name: "Java", graspDegree: 80 },
		{ name: "Python", graspDegree: 70 },
		{ name: "DB", graspDegree: 65 },
		{ name: "JavaScript", graspDegree: 60 },
		{ name: "Linux", graspDegree: 40 },
	],
};
