import Vue from "vue";
import App from "./App.vue";

/**
 * 引入第三方组件
 */
// 引入 vue-router 及路由配置
import Router from "vue-router";
import router from "./router";
Vue.use(Router);
// 引入 ElementUI 和主题样式
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
// 引入 Jquery
import $ from "jquery";
Vue.prototype.$ = $;

/**
 * 引入自定义 js，注册 Vue 全局变量
 * 注册方式：Vue.prototype.$appName = 'My App'
 * 调用 App：this.$appName
 */
// Storage 组件
import Storage from "@/utils/storage.js";
Vue.prototype.$Storage = Storage;
// Vuex Store 组件
import VuexStore from "@/utils/vuexStore.js";
Vue.prototype.$VuexStore = VuexStore;
// Axios Request 组件
import * as Request from "@/utils/request";
Vue.prototype.$Request = Request;
// 注册过滤器组件
import * as Filters from "@/utils/filter.js";
Object.keys(Filters).forEach((key) => {
	Vue.filter(key, Filters[key]);
});
// Common 组件
import * as Common from "@/utils/common.js";
Vue.prototype.$Common = Common;
// TestData 组件
import * as TestData from "@/utils/testData.js";
Vue.prototype.$TestData = TestData;

/**
 * 引入 css
 */
// 引入图标库样式
import "font-awesome/css/font-awesome.min.css";

// 启动时控制台不打印 productionTip 消息
Vue.config.productionTip = false;

// 使用 router.beforeEach 注册一个全局前置守卫（路由守卫），进行登录验证
router.beforeEach((to, from, next) => {
	// 根据路由配置，动态修改 title
	if (to.name) {
		document.title = to.name;
	}

	if (to.name == null) {
		next("/login");
	}

	if (to.meta.requireAuth) {
		if (VuexStore.getters.isLogin) {
			next();
		} else {
			next("/login");
		}
	} else {
		next();
	}
});

new Vue({
	render: (h) => h(App),
	router: router,
}).$mount("#app");
