/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
	return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

/**
 * Upper case first char
 * {{ user.userName | upperCaseFirst }}
 * @param {String} string
 */
export function upperCaseFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

// 去掉 html 标签
export function delHtmlTag(value) {
	return value.replace(/<[^>]+>/g, "");
	// return value.replace(/<.*?>/gi, "");
}

/**
 * 字符串超长替换省略号
 * {{ model.name | ellipsis }}
 */
export function ellipsis(value) {
	if (!value) return "";
	if (value.length > 5) {
		return value.slice(0, 5) + "...";
	}
	return value;
}

export function ellipsis4(value) {
	if (!value) return "";
	if (value.length > 4) {
		return value.slice(0, 4) + "...";
	}
	return value;
}

export function ellipsis10(value) {
	if (!value) return "";
	if (value.length > 10) {
		return value.slice(0, 10) + "...";
	}
	return value;
}

export function ellipsis15(value) {
	if (!value) return "";
	if (value.length > 15) {
		return value.slice(0, 15) + "...";
	}
	return value;
}

export function ellipsis20(value) {
	if (!value) return "";
	if (value.length > 20) {
		return value.slice(0, 20) + "...";
	}
	return value;
}

export function ellipsis30(value) {
	if (!value) return "";
	if (value.length > 30) {
		return value.slice(0, 30) + "...";
	}
	return value;
}

export function ellipsis50(value) {
	if (!value) return "";
	if (value.length > 50) {
		return value.slice(0, 50) + "...";
	}
	return value;
}

export function ellipsis70(value) {
	if (!value) return "";
	if (value.length > 70) {
		return value.slice(0, 70) + "...";
	}
	return value;
}

export function ellipsis100(value) {
	if (!value) return "";
	if (value.length > 100) {
		return value.slice(0, 100) + "...";
	}
	return value;
}

// 日期字符串省掉时分秒
export function buildDateString(value) {
	if (!value) return "";
	if (value.length > 10) {
		return value.slice(0, 10);
	}
	return value;
}

// 日期字符串保留年份
export function buildYearString(value) {
	if (!value) return "";
	if (value.length > 4) {
		return value.slice(0, 4);
	}
	return value;
}

// 温度格式化
export function formatTemperature(value) {
	if (!value) return "";
	return value + "°";
}

// 金钱格式化
export function moneyFormats(value) {
	let a = Number(value);
	let b = a.toLocaleString("zh", { style: "currency", currency: "CNY" });
	return b;
}

// 保留两位小数
export function numberFixed(value) {
	let a = parseFloat(value);
	return a.toFixed(2);
}

// 数字格式化
/**
 * 数字格式化
 * 123\1.2K\1.2W
 */
export function numberFormats(value) {
	let a = Number(value);
	if (a < 1000) {
		return a;
	} else if (a >= 1000 && a < 10000) {
		return (a / 1000).toFixed(1) + "K";
	} else if (a >= 10000 && a < 10000000) {
		return (a / 10000).toFixed(1) + "W";
	} else if (a >= 10000000 && a < 100000000) {
		return (a / 10000000).toFixed(1) + "KW";
	} else if (a >= 100000000 && a < 1000000000000) {
		return (a / 100000000).toFixed(1) + "E";
	} else {
		return (a / 1000000000000).toFixed(1) + "WE";
	}
}

// 秒数格式化
export function secondsFormat(value) {
	var secondTime = parseInt(value); // 秒
	var minuteTime = 0; // 分
	var hourTime = 0; // 小时
	var dayTime = 0; // 天
	var result = "";
	if (value < 60) {
		result = secondTime + "";
	} else {
		if (secondTime >= 60) {
			minuteTime = parseInt(secondTime / 60);
			secondTime = parseInt(secondTime % 60);
			if (minuteTime >= 60) {
				hourTime = parseInt(minuteTime / 60);
				minuteTime = parseInt(minuteTime % 60);
				if (hourTime >= 24) {
					dayTime = parseInt(hourTime / 24);
					hourTime = parseInt(hourTime % 24);
				}
			}
		}
		if (secondTime > 0) {
			secondTime = parseInt(secondTime) >= 10 ? secondTime : "0" + secondTime;
			result = "" + secondTime;
		}
		if (minuteTime > 0) {
			minuteTime = parseInt(minuteTime) >= 10 ? minuteTime : "0" + minuteTime;
			result = "" + minuteTime + ":" + result;
		}
		if (hourTime > 0) {
			result = "" + parseInt(hourTime) + ":" + result;
		}
		if (dayTime > 0) {
			result = "" + parseInt(dayTime) + ":" + result;
		}
	}
	return result;
}

// 斜杠符号添加空格
export function slashAddSpace(value) {
	return value.replaceAll("/", " / ");
}
